import { EXPERIENCE } from "../constants";
var STRIP_FROM_USER_ID = /%E2%80%8B/g;
export var optionsMapping = [
    {
        get: function (_a) {
            var buildHash = _a.buildHash;
            return buildHash;
        },
        has: function (_a) {
            var buildHash = _a.buildHash;
            return Boolean(buildHash);
        },
        key: "version",
    },
    {
        get: function (_a) {
            var connectionId = _a.connectionId;
            return connectionId;
        },
        has: function (_a) {
            var connectionId = _a.connectionId;
            return Boolean(connectionId);
        },
        key: "connectionId",
    },
    {
        get: function (_a) {
            var anonymousId = _a.anonymousId;
            return anonymousId === null || anonymousId === void 0 ? void 0 : anonymousId.replace(STRIP_FROM_USER_ID, "");
        },
        has: function (_a) {
            var anonymousId = _a.anonymousId;
            return Boolean(anonymousId);
        },
        key: "ajs_aid",
    },
    {
        get: function (_a) {
            var apiRoot = _a.apiRoot;
            return apiRoot;
        },
        has: function (_a) {
            var apiRoot = _a.apiRoot;
            return Boolean(apiRoot);
        },
        key: "apiRoot",
    },
    {
        get: function (_a) {
            var authOnly = _a.authOnly;
            return authOnly;
        },
        has: function () { return true; },
        key: "authOnly",
    },
    {
        get: function (_a) {
            var context = _a.context;
            return context;
        },
        has: function (_a) {
            var context = _a.context;
            return Boolean(context);
        },
        key: "context",
    },
    {
        get: function () { return "1"; },
        has: function (_a) {
            var debugKey = _a.debugKey;
            return Boolean(debugKey);
        },
        key: "t",
    },
    {
        get: function (_a) {
            var delayFullStory = _a.delayFullStory;
            return delayFullStory;
        },
        has: function (_a) {
            var delayFullStory = _a.delayFullStory;
            return Boolean(delayFullStory);
        },
        key: "delayFullStory",
    },
    {
        get: function (_a) {
            var disableFullStory = _a.disableFullStory;
            return disableFullStory;
        },
        has: function (_a) {
            var disableFullStory = _a.disableFullStory;
            return Boolean(disableFullStory);
        },
        key: "disableFullStory",
    },
    {
        get: function (_a) {
            var envKey = _a.envKey;
            return envKey;
        },
        has: function (_a) {
            var envKey = _a.envKey;
            return Boolean(envKey);
        },
        key: "trellis_env",
    },
    {
        get: function (_a) {
            var externalPartnerId = _a.externalPartnerId;
            return externalPartnerId;
        },
        has: function (_a) {
            var externalPartnerId = _a.externalPartnerId, urlTrackingParams = _a.urlTrackingParams;
            return Boolean(externalPartnerId) && !urlTrackingParams;
        },
        key: "external_partner_id",
    },
    {
        get: function (_a) {
            var features = _a.features;
            return features;
        },
        has: function (_a) {
            var features = _a.features;
            return Boolean(features);
        },
        key: "features",
    },
    {
        get: function (_a) {
            var isSavvyWidget = _a.isSavvyWidget;
            return isSavvyWidget;
        },
        has: function (_a) {
            var isSavvyWidget = _a.isSavvyWidget;
            return Boolean(isSavvyWidget);
        },
        key: "isSavvyWidget",
    },
    {
        get: function (_a) {
            var key = _a.key;
            return key;
        },
        has: function () { return true; },
        key: "key",
    },
    {
        get: function (_a) {
            var policyOnly = _a.policyOnly;
            return policyOnly;
        },
        has: function () { return true; },
        key: "policyOnly",
    },
    {
        get: function (_a) {
            var preselectedIssuerSlug = _a.preselectedIssuerSlug;
            return preselectedIssuerSlug;
        },
        has: function (_a) {
            var preselectedIssuerSlug = _a.preselectedIssuerSlug;
            return Boolean(preselectedIssuerSlug);
        },
        key: "ps",
    },
    {
        get: function (_a) {
            var savvyAnonymousId = _a.savvyAnonymousId;
            return savvyAnonymousId === null || savvyAnonymousId === void 0 ? void 0 : savvyAnonymousId.replace(STRIP_FROM_USER_ID, "");
        },
        has: function (_a) {
            var savvyAnonymousId = _a.savvyAnonymousId;
            return Boolean(savvyAnonymousId);
        },
        key: "savvyAnonymousId",
    },
    {
        get: function (_a) {
            var savvyClientId = _a.savvyClientId;
            return savvyClientId;
        },
        has: function (_a) {
            var savvyClientId = _a.savvyClientId;
            return Boolean(savvyClientId);
        },
        key: "savvyClientId",
    },
    {
        get: function (_a) {
            var savvyUserId = _a.savvyUserId;
            return savvyUserId === null || savvyUserId === void 0 ? void 0 : savvyUserId.replace(STRIP_FROM_USER_ID, "");
        },
        has: function (_a) {
            var savvyUserId = _a.savvyUserId;
            return Boolean(savvyUserId);
        },
        key: "savvyUserId",
    },
    {
        get: function (_a) {
            var showOnCloseAds = _a.showOnCloseAds;
            return showOnCloseAds;
        },
        has: function (_a) {
            var showOnCloseAds = _a.showOnCloseAds;
            return Boolean(showOnCloseAds);
        },
        key: "showOnCloseAds",
    },
    {
        get: function (_a) {
            var insuranceType = _a.insuranceType;
            return insuranceType;
        },
        has: function (_a) {
            var insuranceType = _a.insuranceType;
            return Boolean(insuranceType);
        },
        key: "insuranceType",
    },
    {
        get: function (_a) {
            var trellisEnv = _a.trellisEnv;
            return trellisEnv;
        },
        has: function (_a) {
            var trellisEnv = _a.trellisEnv;
            return Boolean(trellisEnv);
        },
        key: "trellis_env",
    },
    {
        get: function (_a) {
            var userId = _a.userId;
            return userId === null || userId === void 0 ? void 0 : userId.replace(STRIP_FROM_USER_ID, "");
        },
        has: function (_a) {
            var userId = _a.userId;
            return Boolean(userId);
        },
        key: "ajs_uid",
    },
    {
        get: function (_a) {
            var utmContent = _a.utmContent;
            return utmContent;
        },
        has: function (_a) {
            var utmContent = _a.utmContent, urlTrackingParams = _a.urlTrackingParams;
            return Boolean(utmContent) && !urlTrackingParams;
        },
        key: "utm_content",
    },
    {
        get: function (_a) {
            var utmMedium = _a.utmMedium;
            return utmMedium;
        },
        // Pass through falsy values but not null/undefined
        has: function (_a) {
            var utmMedium = _a.utmMedium, urlTrackingParams = _a.urlTrackingParams;
            return utmMedium != null && !urlTrackingParams;
        },
        key: "utm_medium",
    },
    {
        get: function (_a) {
            var utmSource = _a.utmSource;
            return utmSource;
        },
        // Pass through falsy values but not null/undefined
        has: function (_a) {
            var utmSource = _a.utmSource, urlTrackingParams = _a.urlTrackingParams;
            return utmSource != null && !urlTrackingParams;
        },
        key: "utm_source",
    },
    {
        get: function (_a) {
            var utmCampaign = _a.utmCampaign;
            return utmCampaign;
        },
        // Pass through falsy values but not null/undefined
        has: function (_a) {
            var utmCampaign = _a.utmCampaign, urlTrackingParams = _a.urlTrackingParams;
            return utmCampaign != null && !urlTrackingParams;
        },
        key: "utm_campaign",
    },
    {
        get: function (_a) {
            var utmTerm = _a.utmTerm;
            return utmTerm;
        },
        // Pass through falsy values but not null/undefined
        has: function (_a) {
            var utmTerm = _a.utmTerm, urlTrackingParams = _a.urlTrackingParams;
            return utmTerm != null && !urlTrackingParams;
        },
        key: "utm_term",
    },
    {
        get: function (_a) {
            var webhook = _a.webhook;
            return webhook;
        },
        has: function () { return true; },
        key: "webhook",
    },
    {
        get: function (_a) {
            var urlTrackingParams = _a.urlTrackingParams;
            return urlTrackingParams;
        },
        has: function (_a) {
            var urlTrackingParams = _a.urlTrackingParams;
            return Boolean(urlTrackingParams);
        },
        key: "url_tracking_params",
    },
    {
        get: function (_a) {
            var experience = _a.experience, accountReferenceId = _a.accountReferenceId;
            return experience || (accountReferenceId ? EXPERIENCE.PREFILL : experience);
        },
        has: function (_a) {
            var experience = _a.experience, accountReferenceId = _a.accountReferenceId;
            return Boolean(experience || accountReferenceId);
        },
        key: "experience",
    },
    {
        get: function (_a) {
            var skipQualificationQuestions = _a.skipQualificationQuestions;
            return skipQualificationQuestions;
        },
        has: function (_a) {
            var skipQualificationQuestions = _a.skipQualificationQuestions;
            return Boolean(skipQualificationQuestions);
        },
        key: "skip_qualification_questions",
    },
    {
        get: function (_a) {
            var closeConfirmation = _a.closeConfirmation;
            return closeConfirmation;
        },
        has: function (_a) {
            var closeConfirmation = _a.closeConfirmation;
            return Boolean(closeConfirmation);
        },
        key: "close_confirmation",
    },
    {
        get: function (_a) {
            var client_user_id = _a.client_user_id;
            return client_user_id;
        },
        has: function (_a) {
            var client_user_id = _a.client_user_id;
            return Boolean(client_user_id);
        },
        key: "client_user_id",
    },
    {
        get: function (_a) {
            var application_id = _a.application_id;
            return application_id;
        },
        has: function (_a) {
            var application_id = _a.application_id;
            return Boolean(application_id);
        },
        key: "application_id",
    },
    {
        get: function (_a) {
            var isWebView = _a.isWebView;
            return isWebView;
        },
        has: function (_a) {
            var isWebView = _a.isWebView;
            return typeof isWebView === "boolean";
        },
        key: "is_web_view",
    },
    {
        get: function (_a) {
            var enableFormExperience = _a.enableFormExperience;
            return enableFormExperience;
        },
        has: function (_a) {
            var enableFormExperience = _a.enableFormExperience;
            return Boolean(enableFormExperience);
        },
        key: "enableFormExperience",
    },
    {
        get: function (_a) {
            var hideUninsuredButton = _a.hideUninsuredButton;
            return hideUninsuredButton;
        },
        has: function (_a) {
            var hideUninsuredButton = _a.hideUninsuredButton;
            return Boolean(hideUninsuredButton);
        },
        key: "hideUninsuredButton",
    },
    {
        get: function (_a) {
            var manualFormsButtonText = _a.manualFormsButtonText;
            return manualFormsButtonText;
        },
        has: function (_a) {
            var manualFormsButtonText = _a.manualFormsButtonText;
            return Boolean(manualFormsButtonText);
        },
        key: "manualFormsButtonText",
    },
    {
        get: function (_a) {
            var closeOnUnsupportedIssuer = _a.closeOnUnsupportedIssuer;
            return closeOnUnsupportedIssuer;
        },
        has: function (_a) {
            var closeOnUnsupportedIssuer = _a.closeOnUnsupportedIssuer;
            return Boolean(closeOnUnsupportedIssuer);
        },
        key: "closeOnUnsupportedIssuer",
    },
    {
        get: function (_a) {
            var products = _a.products;
            return products === null || products === void 0 ? void 0 : products.join(",");
        },
        has: function (_a) {
            var products = _a.products;
            return Boolean(products);
        },
        key: "products",
    },
    {
        get: function (_a) {
            var onboardExperience = _a.onboardExperience;
            return onboardExperience;
        },
        has: function (_a) {
            var onboardExperience = _a.onboardExperience;
            return Boolean(onboardExperience);
        },
        key: "onboardExperience",
    },
    {
        get: function (_a) {
            var accountReferenceId = _a.accountReferenceId;
            return accountReferenceId;
        },
        has: function (_a) {
            var accountReferenceId = _a.accountReferenceId;
            return Boolean(accountReferenceId);
        },
        key: "accountReferenceId",
    },
    {
        get: function (_a) {
            var isSavvyAgent = _a.isSavvyAgent;
            return isSavvyAgent;
        },
        has: function (_a) {
            var isSavvyAgent = _a.isSavvyAgent;
            return typeof isSavvyAgent === "boolean";
        },
        key: "isSavvyAgent",
    },
    {
        get: function (_a) {
            var gclid = _a.gclid;
            return gclid;
        },
        has: function (_a) {
            var gclid = _a.gclid;
            return Boolean(gclid);
        },
        key: "gclid",
    },
];
export default function buildIframeURL(trellisRoot, options) {
    var urlTrackingParams = options.urlTrackingParams;
    var params = new URLSearchParams(urlTrackingParams !== null && urlTrackingParams !== void 0 ? urlTrackingParams : "");
    optionsMapping
        .filter(function (_a) {
        var has = _a.has;
        return has(options);
    })
        .forEach(function (_a) {
        var key = _a.key, get = _a.get;
        params.set(key, "".concat(get(options)));
    });
    return "".concat(trellisRoot, "?").concat(params.toString());
}
